import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { connect, useSelector } from 'react-redux';
import Spinner from '../layout/Spinner';
import ProfileTop from './ProfileTop';
import ProfileAbout from './ProfileAbout';
import ProfileExperience from './ProfileExperience';
import ProfileEducation from './ProfileEducation';
import ProfileGithub from './ProfileGithub';
import { getProfileById } from '../../actions/profile';

import { FaMinus, FaPlus } from "react-icons/fa6";
import { CiCirclePlus } from "react-icons/ci";
import CreateProfile from './CreateProfile';

const Profile = ({
  getProfileById,
  profile: { profile, loading },
  auth,
  match
}) => {

  const [openSkill, setOpenSkill] = useState(true);
  const [openExperience, setOpenExperience] = useState(true);
  const [openEducation, setOpenEducation] = useState(true);
  const [addExperience, SetAddExperience] = useState(false);
  const [addEducation, SetAddEducation] = useState(false);

  const SkillsOpenDailogHandler = () => {
    setOpenSkill(!openSkill);
  };
  const ExpericenceOpenDailogHandler = () => {
    setOpenExperience(!openExperience);
  };
  const EducationOpenDailogHandler = () => {
    setOpenEducation(!openEducation);
  };

  const AddExperienceHandler = () => {
    SetAddExperience(!addExperience);
  };
  const AddEducationHandler = () => {
    SetAddEducation(!addEducation);
  };

  // const auth = useSelector((state) => state.auth);

  useEffect(() => {
    getProfileById(match.params.id);
  }, [getProfileById, match.params.id]);

  return (
    <Fragment>
      {
        loading ? <Spinner /> : (
          profile === null &&
          <>
            {
              auth?.user?._id === match.params.id ? (
                <CreateProfile />
              ) : (
                <p>Profile has not been setup by the person whose profile you are trying to view</p>
              )
            }
          </>
        )
      }
      {
        profile !== null && (
          <div className="flex gap-2 flex-col">
            {auth.isAuthenticated && auth.loading === false && auth.user._id ===
              profile.user._id && (
                <div className="flex justify-center items-center my-4">
                  {/* Edit and create profile has same page */}
                  <Link to='/create-profile'
                    className="btn px-6 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition-all duration-300 transform active:scale-95">
                    Edit Profile
                  </Link>
                </div>
              )}


            <ProfileTop profile={profile} />
            <div className="flex gap-2 flex-col mt-6">

              <ProfileAbout profile={profile} SkillsOpenDailogHandler={SkillsOpenDailogHandler} openSkill={openSkill} />

              <div className=" mx-2  border-2 border-black/10">
                <div className="py-1 px-2 font-semibold flex  justify-between px-1 items-center bg-black/10 ">
                  <p className="text-xl">Experience</p>
                  <span className="">
                    {
                      openExperience ? (
                        <FaMinus
                          onClick={ExpericenceOpenDailogHandler}
                          className="active:rotate-45 transition-all duration-200"
                        />
                      ) : (
                        <FaPlus
                          onClick={ExpericenceOpenDailogHandler}
                          className="active:rotate-45 transition-all duration-200"
                        />
                      )
                    }
                  </span>
                </div>
                {openExperience && (
                  <ul className="mx-2 py-2 ">
                    {
                      profile.experience.length > 0 &&
                      <Fragment>
                        {
                          profile.experience.map((experience) => (
                            <ProfileExperience
                              key={experience._id}
                              experience={experience}
                            />
                          ))
                        }
                      </Fragment>
                    }
                    {
                      auth.isAuthenticated && auth.loading === false && auth.user._id ===
                      profile.user._id &&
                      <Link to='/add-experience'>
                        <div
                          className="flex  items-center justify-center item-start  gap-2 py-3 bg-black/10 active:scale-[1.015] transitions-all duration-400 "
                        >
                          <li className="text-lg">Add Experience</li>
                          <span className="text-2xl"><CiCirclePlus /></span>
                        </div>
                      </Link>
                    }
                  </ul>
                )}
              </div>

              <div className=" mx-2  border-2 border-black/10 ">
                <div className="py-1 font-semibold  flex justify-between px-2  px-1 items-center bg-black/10 ">
                  <p className="text-xl">Education</p>
                  <span>
                    {
                      openEducation ? (
                        <FaMinus
                          onClick={EducationOpenDailogHandler}
                          className="active:rotate-45 transition-all duration-200"
                        />
                      ) : (
                        <FaPlus
                          onClick={EducationOpenDailogHandler}
                          className="active:rotate-45 transition-all duration-200"
                        />
                      )
                    }
                  </span>
                </div>
                {openEducation && (
                  <ul className="mx-2 py-2 ">
                    {
                      profile.education.length > 0 &&
                      <Fragment>
                        {
                          profile.education.map((education) => (
                            <ProfileEducation
                              key={education._id}
                              education={education}
                            />
                          ))
                        }
                      </Fragment>
                    }
                    {
                      auth.isAuthenticated && auth.loading === false && auth.user._id ===
                      profile.user._id &&
                      <Link to='/add-education'>
                        <div
                          className="flex  items-center justify-center item-start  gap-2 py-3 bg-black/10 active:scale-[1.015] transitions-all duration-400 "
                        >
                          <li className="text-lg">Add Education</li>
                          <span className="text-2xl"><CiCirclePlus /></span>
                        </div>
                      </Link>
                    }

                  </ul>
                )}
              </div>
              {
                auth?.user?.role === 'salesPerson' &&
                <>
                  <p className='mx-2 bg-black/10 py-2 text-center text-black/80'>Referral Code:  {auth.user.referralCode}</p>
                </>
              }
            </div>
          </div>
        )
      }

    </Fragment >
  );
}
Profile.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, { getProfileById })(Profile);
