import React from 'react'
import { Link } from 'react-router-dom';
import ImageCard from './ImageCard'
import NoProfessionalsFound from './NoProfessionalsFound'
import ProfileCard from '../../profile/ProfileCard';

const ServiceLayout = ({ professionals, cardType }) => {
    const CardComponent = cardType === 'profile' ? ProfileCard : ImageCard;

    return (
        <div className="mt-3 lg:grid lg:grid-cols-3 gap-3 md:grid md:grid-cols-2">
            {
                professionals?.length > 0 ? (
                    professionals.map((professional) => (
                        <>
                            {
                                cardType === 'profile' ? (
                                    (professional?.images?.length > 0 ||
                                        (professional.properties && Object.keys(professional.properties).length > 0)
                                    ) ? (
                                        <Link to={`show-service-details/${professional._id}`}>
                                            <CardComponent key={professional._id} professional={professional} />
                                        </Link>
                                    ) : (
                                        <CardComponent key={professional._id} professional={professional} />
                                    )
                                ) : (
                                    (professional?.images?.length > 0 ||
                                        (professional.properties && Object.keys(professional.properties).length > 0)
                                    ) ? (
                                        <Link to={`show-service-details/${professional._id}`}>
                                            <CardComponent key={professional._id} professional={professional} />
                                        </Link>
                                    ) : (
                                        <CardComponent key={professional._id} professional={professional} />
                                    )
                                )
                            }
                        </>
                    ))
                ) : (
                    <NoProfessionalsFound />
                )
            }
        </div>
    )
}

export default ServiceLayout