import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProfile, getCurrentProfile } from '../../actions/profile';
import { generatePublicImgURL } from '../../urlConfig';
import { ADMIN, SERVICE_PROFESSIONALS, SOCIAL_MEDIA_LOGOS } from '../aNewGoToUI/utils/constants/s3Constants';
import { compressImages } from '../../utils/compressImages';

const ProfileForm = () => {

  const profile = useSelector((state) => state.profile);
  const [myProfile, setMyProfile] = useState(profile.profile)

  const [formData, setFormData] = useState({
    company: '',
    website: '',
    location: '',
    status: '',
    skills: '',
    githubusername: '',
    bio: '',
    twitter: '',
    facebook: '',
    linkedin: '',
    youtube: '',
    instagram: ''
  });

  const {
    company,
    website,
    location,
    status,
    skills,
    githubusername,
    bio,
    twitter,
    facebook,
    linkedin,
    youtube,
    instagram
  } = formData;

  const [profileImage, setProfileImage] = useState(!myProfile?.profileImage ? null : profile?.profileImage);
  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getCurrentProfile());
  }, []);

  useEffect(() => {
    if (profile.profile) {
      setMyProfile(profile.profile);

      // Populate formData with fetched profile data
      setFormData({
        company: profile.profile.company || '',
        website: profile.profile.website || '',
        location: profile.profile.location || '',
        status: profile.profile.status || '',
        skills: profile.profile.skills ? profile.profile.skills : '',
        githubusername: profile.profile.githubusername || '',
        bio: profile.profile.bio || '',
        twitter: profile.profile.social?.twitter || '',
        facebook: profile.profile.social?.facebook || '',
        linkedin: profile.profile.social?.linkedin || '',
        youtube: profile.profile.social?.youtube || '',
        instagram: profile.profile.social?.instagram || '',
      });

      // Set profile image if present
      if (profile.profile.profileImage) {
        setProfileImage(profile.profile.profileImage);
      }
    }
  }, [profile.profile]);

  console.log(profile, myProfile, formData, profileImage)

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      try {
        const compressedImages = await compressImages([file]); // Compress the image
        // Check if compressed image is a Blob and convert to File if necessary
        const compressedImage = compressedImages[0];
        if (compressedImage instanceof Blob && !(compressedImage instanceof File)) {
          const fileFromBlob = new File([compressedImage], file.name, {
            type: compressedImage.type,
            lastModified: Date.now(),
          });
          setProfileImage(fileFromBlob); // Update state with the new File object
        } else {
          setProfileImage(compressedImage); // If it's already a File, update the state
        }
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
    console.log(e.target.files[0])
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    // Add text fields
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    // Add profile image only if it's a File object
    if (profileImage instanceof File) {
      formDataToSend.append('profileImage', profileImage, profileImage.name);
    }
    dispatch(createProfile(formDataToSend, history));
  };

  return (
    <Fragment>
      <h1 className="text-center py-3 text-xl font-bold text-gray-900 bg-black/20 ">
        Create or Update Your Profile
      </h1>
      <p className="text-xs text-gray-600 mx-2 mt-2">
        <i className="fas fa-user" />
        Let's get some information to make your profile standout
      </p>
      <form onSubmit={e => onSubmit(e)}>
        <div className="flex flex-col gap-2 mx-2 mt-2">
          <select className="p-2" name="status" value={status} onChange={onChange}>
            <option>* Select Professional Status</option>
            <option value='Company'>Company</option>
            <option value='Professional'>Professional</option>
            <option value='LookingForJobs'>Looking for Jobs</option>
            <option value='Other'>Other</option>
          </select>
        </div>
        <div className="flex flex-col gap-2 mx-2 mt-2">
          <input
            type="text"
            placeholder="Skills *"
            name="skills"
            value={skills}
            onChange={onChange}
            required
            className="bg-black/5 p-2"
          />
          <small className="form-text text-muted">Separate by commas (e.g., HTML, CSS, JavaScript).</small>
        </div>
        <div className="flex flex-col gap-2 mx-2 mt-2">
          <label htmlFor="profileImage" className="block text-sm font-medium text-gray-600">
            Profile Image
          </label>
          {profileImage && (
            <div className="mt-2">
              <img
                src={
                  profileImage instanceof File ?
                    URL.createObjectURL(profileImage) // Preview for newly uploaded file
                    : generatePublicImgURL(SERVICE_PROFESSIONALS + '/' + profileImage) // Existing S3 image URL
                }
                alt="Profile Preview"
                className="w-20 h-20 rounded-full object-cover"
              />
            </div>
          )}
          <input
            type="file"
            id="profileImage"
            accept="image/*"
            onChange={handleProfileImageChange}
            className="bg-black/5 p-2"
          />
        </div>
        <div className="flex flex-col gap-2 mx-2 mt-2">
          <textarea
            placeholder="A short bio of yourself"
            name="bio"
            value={bio}
            onChange={onChange}
            className="bg-black/5 p-2"
          />
          <small>Tell us a little about yourself</small>
        </div>

        <div className="mt-3 mx-2 bg-black/10 text-center py-2 ">
          <button
            onClick={() => toggleSocialInputs(!displaySocialInputs)}
            type="button"
          >
            Add Social Network Links
          </button>
          <span className="ml-2 text-sm text-gray-500">Optional</span>
        </div>

        {displaySocialInputs && (
          <Fragment>
            <div className=" mx-2  flex flex-col  px-2  py-1  gap-1  mt-2">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-600"
              >
                Twitter URL
              </label>
              <div className="border-2 border-black/10 flex gap-2 px-2 py-2 ">
                <img src={generatePublicImgURL(ADMIN + '/' + SOCIAL_MEDIA_LOGOS + '/' + 'TwitterLogo.png')} alt="twitter_logo" width={24} />
                <input
                  type="text"
                  // placeholder="Twitter URL"
                  name="twitter"
                  value={twitter || ""}
                  onChange={(e) => onChange(e)}
                  className="outline-none"
                />
              </div>
            </div>

            <div className="mx-2  flex flex-col  px-2  py-1  gap-1  mt-2">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-600"
              >
                Facebook URL
              </label>
              <div className="border-2 border-black/10 flex gap-2 px-2 py-2 ">
                <img src={generatePublicImgURL(ADMIN + '/' + SOCIAL_MEDIA_LOGOS + '/' + 'FacebookLogo.png')} alt="facebook_logo" width={24} />

                <input
                  type="text"
                  // placeholder="Facebook URL"
                  name="facebook"
                  value={facebook || ""}
                  onChange={(e) => onChange(e)}
                  className="outline-none"
                />
              </div>
            </div>

            <div className="mx-2  flex flex-col  px-2  py-1  gap-1  mt-2">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-600"
              >
                Youtube URL
              </label>
              <div className="border-2 border-black/10 flex gap-2 px-2 py-2 ">
                <img src={generatePublicImgURL(ADMIN + '/' + SOCIAL_MEDIA_LOGOS + '/' + 'YouTubeLogo.png')} alt="twitter_logo" width={24} />

                <input
                  type="text"
                  // placeholder="YouTube URL"
                  name="youtube"
                  value={youtube || ""}
                  onChange={(e) => onChange(e)}
                  className="outline-none"
                />
              </div>
            </div>

            <div className="mx-2  flex flex-col  px-2  py-1  gap-1  mt-2">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-600"
              >
                Linkedin URL
              </label>
              <div className="border-2 border-black/10 flex gap-2 px-2 py-2 ">
                <img src={generatePublicImgURL(ADMIN + '/' + SOCIAL_MEDIA_LOGOS + '/' + 'LinkedinLogo.png')} alt="linkedin_logo" width={24} />

                <input
                  type="text"
                  // placeholder="Linkedin URL"
                  name="linkedin"
                  value={linkedin || ""}
                  onChange={(e) => onChange(e)}
                  className="outline-none"
                />
              </div>
            </div>

            <div className="mx-2  flex flex-col  px-2  py-1  gap-1  mt-2">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-600"
              >
                Instagram URL
              </label>
              <div className="border-2 border-black/10 flex gap-2 px-2 py-2 ">
                <img src={generatePublicImgURL(ADMIN + '/' + SOCIAL_MEDIA_LOGOS + '/' + 'InstagramLogo.png')} alt="instagram_logo" width={24} />

                <input
                  type="text"
                  // placeholder="Instagram URL"
                  name="instagram"
                  value={instagram || ""}
                  onChange={(e) => onChange(e)}
                  className="outline-none"
                />
              </div>
            </div>
          </Fragment>
        )}


        <div className="w-full flex justify-center gap-5 mt-3">
          <Link className="p-[0.4em] px-5 rounded-md bg-green-400 active:scale-95 transitions-all duration-200" to="/">
            Go Back
          </Link>
          <input
            type="submit"
            className="p-1 px-6 rounded-md bg-blue-400"
          />
        </div>
      </form>
    </Fragment>
  );
}

export default ProfileForm