import React from 'react';
import PropTypes from 'prop-types';
import { generatePublicImgURL } from '../../urlConfig';
import { ADMIN, ICONS_AND_PHOTOS, SERVICE_PROFESSIONALS } from '../aNewGoToUI/utils/constants/s3Constants';

const ProfileTop = ({
  profile: {
    status,
    company,
    location,
    website,
    social,
    profileImage,
    user: { name, avatar }
  }
}) => {
  return (
    <div className="bg-black/10  h-[150px] -mt-3  rounded-b-full relative">
      <p className="text-md font-semibold py-1 text-3xl absolute left-1/2 transform -translate-x-1/2 top-3 w-full text-center text-black/70">
        {name}
      </p>
      <p className="text-md  text-gray-600 text-center top-12 absolute left-1/2 transform -translate-x-1/2 ">
        @ {name}
      </p>
      <div className="w-[6.5em] absolute -bottom-[5em] left-1/2 transform -translate-x-1/2 -translate-y-1/2  border-2 rounded-full border-black/10">
        <img
          src={ profileImage 
            ? generatePublicImgURL(SERVICE_PROFESSIONALS + '/' + profileImage)
            : generatePublicImgURL(ADMIN + '/' + ICONS_AND_PHOTOS + '/' + 'profile.png')}
          alt="profile_image"
          width={400}
          height={400}
        />
      </div>
    </div>
  );
};

ProfileTop.propTypes = {
  profile: PropTypes.object.isRequired
};

export default ProfileTop;