import React, { useState } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Carousel from "react-multi-carousel";
import PhoneInput from 'react-phone-input-2'
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { compressImages } from '../../utils/compressImages';
import { addProfessionalDetails } from '../../actions/workerCategory';
import { SERVICE_PROFESSIONALS } from '../aNewGoToUI/utils/constants/s3Constants';
import { generatePublicImgURL } from '../../urlConfig';
import getCompleteMobileNumberWithPlus from '../../utils/MobileNumber';

const AddProfessionalDetails = () => {

  const location = useLocation();
  const properties = location.state ? location.state.properties : null;
  const servicePropertyFields = location.state ? location.state.servicePropertyFields : null;
  const profId = location.state ? location.state.profId : null;

  const dispatch = useDispatch();
  const history = useHistory();
  // console.log("servicePropertyFields ", servicePropertyFields)
  // console.log("properties ", properties)

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

  const getClassNames = (depth) => {
    // Define different classes for different depths
    const baseClass = 'w-full';
    const depthClasses = [
      ' bg-[#ffffff]',  // Depth 0
      ' bg-purple-100 shadow-md',  // Depth 1
      ' bg-[#E3F4F4]',   // Depth 2
      ' bg-[#F8F6F4]', // Depth 3
    ];
    return `${baseClass} ${depthClasses[depth] || 'bg-gray-100'}`; // Default to gray if depth exceeds predefined classes
  };

  const initializeDataMap = (servicePropertyFields, initialData = {}, nestedFields = []) => {
    const dataMap = {};
    Object.entries(servicePropertyFields).forEach(([fieldName, fieldValue]) => {
      const nestedFieldsForCurrentField = [...nestedFields, fieldName];
      const nestedFieldPath = nestedFieldsForCurrentField.join('.');

      if (typeof fieldValue === 'object' && fieldName !== "multiple") {

        if (fieldValue.type) {
          if (fieldName === 'Consent') {
            dataMap[fieldName] = '';
          }
          else if (hasNestedFieldPath(initialData, nestedFieldsForCurrentField)) {
            dataMap[fieldName] = getNestedFieldValue(initialData, nestedFieldsForCurrentField);
          } else {
            dataMap[fieldName] = initializeField(fieldValue);
          }
        } else if (fieldValue.multiple) {
          dataMap[fieldName] = initializeMultipleField(fieldValue, initialData, nestedFieldPath, nestedFieldsForCurrentField);
        } else {
          dataMap[fieldName] = initializeDataMap(fieldValue, initialData, nestedFieldsForCurrentField);
        }
      }

    });
    return dataMap;
  };

  // Helper function to check if a nested field path exists in an object
  const hasNestedFieldPath = (object, nestedPath) => {
    return nestedPath.every(key => (object = object[key]) !== undefined);
  };

  // Helper function to get the value of a nested field path in an object
  const getNestedFieldValue = (initialData, nestedFieldsForCurrentField) => {
    return nestedFieldsForCurrentField.reduce((acc, key) => acc[key], initialData);
  };

  const initializeField = (fieldValue) => {
    if (fieldValue.multiple) {
      return [];
    }
    switch (fieldValue.type) {
      case 'String':
        return '';
      case 'Number':
        return null;
      case 'Boolean':
        return '';
      case 'Date':
        return null;
      case 'File':
        return [];
      default:
        return {};
    }
  };

  const initializeMultipleField = (fieldValue, initialData, nestedFieldPath, nestedFieldsForCurrentField) => {
    let initialDataArray;
    if(hasNestedFieldPath(initialData, nestedFieldsForCurrentField)) {
      initialDataArray = getNestedFieldValue(initialData, nestedFieldsForCurrentField);
    }
    if (Array.isArray(initialDataArray) && initialDataArray.length > 0) {
      // console.log("in nf array", nestedFieldPath, initialData[nestedFieldPath])
      return initialDataArray.map((item, index) =>
        initializeDataMap(fieldValue, initialData, [...nestedFieldsForCurrentField, index.toString()])
      );
    } else {
      return [initializeDataMap(fieldValue, initialData, [...nestedFieldsForCurrentField, 0])];
    }
  };

  // console.log("SPF", servicePropertyFields)
  // console.log("properties", properties)
  const [propertiesFieldMap, setPropertiesFieldMap] = useState(initializeDataMap(servicePropertyFields, properties));
  // console.log("propertiesFieldMap ", propertiesFieldMap)

  // Function to handle property changes
  const handlePropertyChange = async (e, fieldName, nestedFields = [], isPhoneInput = false, isAutoComplete) => {
    const updatedPropertiesFieldMap = { ...propertiesFieldMap };
    // Navigate to the nested object based on the nestedFields array
    let currentObject = updatedPropertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }

    if (isPhoneInput) {
      // Handle phone input specifically
      currentObject[fieldName] = getCompleteMobileNumberWithPlus(e);
    } else if (isAutoComplete) {
      // Handle the case for Autocomplete
      currentObject[fieldName] = e;
    } else {
      const { value, files } = e.target;
      let checked;
      if (e.target.type === 'checkbox') {
        checked = e.target.checked;
      }
      if (files && files.length > 0) {
        // Handle image input
        const compressedImages = await compressImages(files);
        // Update the value of the field with the compressed images
        currentObject[fieldName] = [...currentObject[fieldName], ...compressedImages];
      } else if (typeof checked !== 'undefined') {
        console.log("x")
        // Handle checkbox input
        currentObject[fieldName] = checked;
      } else {
        // Update the value of the field
        if (Array.isArray(currentObject[fieldName])) {
          // If the field value is an array
          const newValue = value.trim(); // Trim the input value
          const index = currentObject[fieldName].indexOf(newValue);
          if (index !== -1) {
            // If the value is already in the array, remove it
            currentObject[fieldName] = currentObject[fieldName].filter((item, idx) => idx !== index);
          } else {
            // Append the value if it doesn't already exist in the array
            currentObject[fieldName] = [...currentObject[fieldName], newValue];
          }
        } else {
          // If the field value is not an array, treat the input value as a single value
          currentObject[fieldName] = value;
        }
      }
    }
    setPropertiesFieldMap(updatedPropertiesFieldMap);
    console.log(propertiesFieldMap);
  };

  // Function to handle adding new items to the array
  const handleAddItem = (e, fieldName, nestedFields, fieldValue) => {
    e.preventDefault();
    const updatedPropertiesFieldMap = { ...propertiesFieldMap };
    let currentObject = updatedPropertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }
    // Append a new item to the array
    currentObject[fieldName].push(initializeDataMap(fieldValue));
    setPropertiesFieldMap(updatedPropertiesFieldMap);
  };

  // Function to render input field for non-nested fields
  const renderInput = (fieldName, nestedFields, required, description) => {
    // Get the current value based on the nestedFields array
    let currentObject = propertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }
    // Update the value of the field
    let value = currentObject[fieldName];
    if (fieldName === 'Mobile') {
      return (
        <PhoneInput
          buttonStyle={{
            border: "1px solid #bdbdbd",
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
          }}
          inputStyle={{
            padding: "20px",
            paddingInline: "50px",
            paddingBlock: '20px',
            border: "1px solid #bdbdbd",
            width: "100%",
            borderRadius: '5px'
          }}
          country={"in"}
          value={value}
          onChange={(e) => handlePropertyChange(e, fieldName, nestedFields, true)}
        />
      );
    } else {
      return (
        <input
          type="text"
          required={required} // Add the required attribute conditionally
          value={value}
          onChange={(e) => handlePropertyChange(e, fieldName, nestedFields)}
          className="border border-gray-400 text-gray-500 p-2 rounded-md w-full"
        />
      );
    }
  };

  // Function to render select dropdown for fields with options
  const renderSelect = (options, fieldName, nestedFields = [], multiple, required) => {
    // Get the current value based on the nestedFields array
    let currentObject = propertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }
    // Update the value of the field
    let value = currentObject[fieldName];
    // If multiple is true and the value is not an array, convert it to an array
    if (multiple && !Array.isArray(value)) {
      value = [value];
    }

    return (
      !multiple ? (
        <select
          value={value}
          onChange={(e) => handlePropertyChange(e, fieldName, nestedFields)}
          multiple={multiple} // Add the multiple attribute conditionally
          required={required} // Add the required attribute conditionally
          className="border border-gray-400 text-gray-500 p-2 rounded-md w-full bg-white active:bg-white px-4"
        >
          <option value="">Select</option>
          {options.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      ) : (
        <Autocomplete
          multiple={multiple} // Add the multiple attribute conditionally
          required={required} // Add the required attribute conditionally
          options={options}
          value={value}
          onChange={(e, newValue) => handlePropertyChange(newValue, fieldName, nestedFields, false, true)}
          renderInput={(params) => (
            <TextField {...params} />
          )}
        />
      )
    );
  };

  // Function to render input field for number fields
  const renderNumberInput = (fieldName, nestedFields, required, description) => {

    // Get the current value based on the nestedFields array
    let currentObject = propertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }
    // Update the value of the field
    let value = currentObject[fieldName];
    return (
      <input
        type="number"
        value={value}
        required={required} // Add the required attribute conditionally
        onChange={(e) => handlePropertyChange(e, fieldName, nestedFields)}
        className="border border-gray-400 text-gray-500 p-2 rounded-md w-full px-4"
      />
    );
  };

  // Function to render input field for date fields
  const renderDateInput = (fieldName, nestedFields, required, description) => {
    // Get the current value based on the nestedFields array
    let currentObject = propertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }
    // Update the value of the field
    let value = currentObject[fieldName];
    return (
      <input
        type="date"
        required={required} // Add the required attribute conditionally
        value={value}
        onChange={(e) => handlePropertyChange(e, fieldName, nestedFields)}
        className="border border-gray-400 text-gray-500 p-2 bg-white rounded-md w-full"
      />
    );
  };

  // Function to render input field for checkbox boolean fields
  const renderCheckBoxInput = (fieldName, nestedFields, required, description) => {
    // Get the current value based on the nestedFields array
    let currentObject = propertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }

    // Update the value of the field
    let value = currentObject[fieldName];

    return (
      <div className="flex mx-2">
        <input
          type="checkbox"
          required={required} // Add the required attribute conditionally
          checked={value} // Set checked to the actual boolean value
          onChange={(e) => handlePropertyChange(e, fieldName, nestedFields)}
          className="border border-gray-400 text-gray-500 p-2 bg-white rounded-md -mt-[1.5em] md:mt-0"
        />
        {description && <label className="ml-2 text-md md:text-sm text-gray-700">{description}</label>}
      </div>
    );
  };

  // Function to render input field for image fields
  const renderImageInput = (fieldName, nestedFields, required, description) => {
    let currentObject = propertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }
    return (
      <div className='grid grid-cols-1'>
        {
          currentObject[fieldName]?.length > 0 && (
            <Carousel
              swipeable={true}
              removeArrowOnDeviceType={["mobile"]}
              dotListClass=""
              itemClass="mb-[6px] ml-3 md:ml-3"
              responsive={responsive}
              autoPlay={1000}
              infinite={true}
            >
              {
                currentObject[fieldName].map((pic, index) =>
                  <img
                    src={generatePublicImgURL(SERVICE_PROFESSIONALS + '/' + pic)}
                    alt={'images'}
                    width={120}
                    height={120}
                    key={index}
                    className="rounded-md hover:scale-105 transitions-all duration-200"
                  />
                )
              }
            </Carousel>
          )
        }
        <input
          type="file" multiple
          required={required} // Add the required attribute conditionally
          onChange={(e) => handlePropertyChange(e, fieldName, nestedFields)}
          className="border border-gray-400 text-gray-500 p-2 bg-white rounded-md w-full"
        />
      </div>
    );
  };

  // Render input field for non-nested fields
  const renderInputField = (fieldName, nestedFields = [], fieldType, required = false, description = '') => {
    switch (fieldType) {
      case 'String':
        return renderInput(fieldName, nestedFields, required, description);
      case 'Number':
        return renderNumberInput(fieldName, nestedFields, required, description);
      case 'Date':
        return renderDateInput(fieldName, nestedFields, required, description);
      case 'Boolean':
        return renderCheckBoxInput(fieldName, nestedFields, required, description)
      case 'File':
        return renderImageInput(fieldName, nestedFields, required);
      default:
        return null;
    }
  };

  const renderSelectField = (fieldName, nestedFields = [], options, fieldType, multiple = false, required = false, description = '') => {
    switch (fieldType) {
      case 'String':
      case 'Number':
        return renderSelect(options, fieldName, nestedFields, multiple, required, description);
      default:
        return null;
    }
  };

  const renderPropertyFields = (servicePropertyFields, nestedFields = [], depth = 0) => {
    return (
      <>
        {
          Object.entries(servicePropertyFields).map(([fieldName, fieldValue]) => (
            <div key={fieldName} className='w-full'>
              {/* Check if the field value is an object (nested fields) */}
              {typeof fieldValue === 'object' && fieldValue.type ?
                (
                  <div className=''>
                    {
                      fieldName !== 'Consent' && (
                        <p className="text-sm text-gray-600 font-semibold text-black mb-3 ml-2">{fieldName}:</p>
                      )
                    }
                    {/* Check if the field is an enum */}
                    {Array.isArray(fieldValue.enum) ? (
                      renderSelectField(fieldName, nestedFields, fieldValue.enum, fieldValue.type, fieldValue.multiple, fieldValue.required, fieldValue.description)
                    ) : (
                      renderInputField(fieldName, nestedFields, fieldValue.type, fieldValue.required, fieldValue.description)
                    )}
                    <br />
                  </div>
                ) : (
                  // Check if fieldValue is an array in propertiesFieldMap but an object in servicePropertiesField
                  (fieldValue.multiple && Array.isArray([...nestedFields, fieldName].reduce((obj, key) => obj && obj[key], propertiesFieldMap))) ? (
                    <div className={`border border-black/5 rounded-xl my-4 ${getClassNames(depth)} inset-shadow ${depth === 0 && 'mx-2'}`}>
                      <p className={` font-bold my-4 text-center mb-6`}>{fieldName}</p>
                      {[...nestedFields, fieldName].reduce((obj, key) => obj && obj[key], propertiesFieldMap).map((item, index) => (

                        <div
                          className="mb-4 grid md:grid-cols-2 gap-6 gap-x-14 p-3 shadow-sm py-8 px-3 mx-2 "
                        >
                          <p className={` font-bold my-4 text-center mb-6`}>{fieldName}-{index + 1 }</p>
                          {/* {`${fieldName}-${index + 1 }`} */}
                          {/* Render input fields for each property in fieldValue according to servicePropertiesField */}
                          {renderPropertyFields(fieldValue, [...nestedFields, fieldName, index.toString()], depth + 1)}
                        </div>
                      ))}
                      {/* Add button to add new item */}
                      <button onClick={(e) => handleAddItem(e, fieldName, nestedFields, fieldValue)}
                        className="blue-gradient text-white font-extrabold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex w-full justify-center  md:flex-none  md:w-[50%] w-[50%] m-auto my-3"
                      >
                        Add {fieldName}
                      </button>
                    </div>
                  ) : (
                    // Render input fields for nested properties recursively
                    <>
                      {
                        fieldName !== 'multiple' && (
                          <div className={`border border-black/5 rounded-xl my-4 ${getClassNames(depth)} inset-shadow ${depth === 0 && 'mx-2'}`}>
                            <p className={`font-bold text-center my-3 mb-6  text-xl`}>{fieldName}</p>
                            <div
                              className={`my-2 grid md:grid-cols-2   gap-5  gap-x-14 px-5 p-3 `}
                            >
                              {renderPropertyFields(fieldValue, [...nestedFields, fieldName], depth + 1)}
                            </div>
                          </div>
                        )
                      }
                    </>
                  )
                )
              }
            </div>
          )
          )
        }
      </>
    );
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Ensure propertiesFieldMap.Consent is true if checked
      if (typeof propertiesFieldMap.Consent !== 'undefined' && propertiesFieldMap.Consent !== true) {
        // Show alert if consent is not given
        alert('Please give consent to proceed.');
        return;
      }
      const form = new FormData();
      const propertiesObj = {
        properties: propertiesFieldMap
      }
      appendToFormData(form, propertiesObj);
      // Logging Form Data entries will comment later after  testing
      for (const entry of form.entries()) {
        console.log(entry);
      }
      dispatch(addProfessionalDetails(form, profId));
      history.push('/loading')
      // Handle success, reset form, show success message, etc.
    } catch (error) {
      console.error('Error adding properties:', error);
      // Handle error, show error message, etc.
    }
  };

  function appendToFormData(form, obj, parentKey = '') {
    if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') {
      // Append string, number, or boolean values directly
      form.append(parentKey, obj);
    }
    else if (isImage(obj)) {
      form.append(parentKey, obj, obj.name);
    } else {
      Object.entries(obj).map(([key, value]) => {
        const newKey = parentKey ? `${parentKey}[${key}]` : key;

        if (Array.isArray(value)) {
          // Handle arrays
          value.forEach((item, index) => {
            const arrayKey = `${newKey}[${index}]`;
            appendToFormData(form, item, arrayKey);
          });
        } else if (typeof value === 'object' && value !== null) {
          // Handle nested objects (excluding files)
          appendToFormData(form, value, newKey);
        } else if (isImage(value)) {
          // Append files as blobs
          const blob = new Blob([value]);
          form.append(newKey, blob, 'file');
        } else {
          // Append other values
          form.append(newKey, value);
        }
      })
    }
  }

  function isImage(value) {
    // Check if the value is an image (you need to define your own criteria for this)
    // For example, you can check if the value is an instance of Blob or File
    return value instanceof Blob || (value instanceof File && value.type.startsWith('image'));
  }

  return (
    <div className='bg-black/5'>
      <div className="flex flex-col items-center justify-center min-h-screen md:w-[80em] m-auto">
        <h2 className="text-2xl md:text-3xl font-extrabold mb-8 text-center my-7 font-bold">Complete Your Information</h2>
        <form onSubmit={handleSubmit}>
          {renderPropertyFields(servicePropertyFields)}
          {/* <Autocomplete
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          onPlaceSelected={(place) => console.log(place)}
        /> */}
          <button
            type="submit"
            className="blue-gradient text-white font-extrabold py-2 px-[4em] rounded focus:outline-none focus:shadow-outline m-auto w-[10em] flex justify-center my-5"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddProfessionalDetails