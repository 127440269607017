import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfessional } from "../../actions/workerCategory";
import Layout from "../layout/Layout";
import Card from "../layout/Card";
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { MdLocalPhone } from "react-icons/md";

import "../style.css";
import { getProfessionals } from "../../actions/workerCategory";
import { checkToShowAddProfessionalDetailsButton } from "../utils/workercategory/WorkerCategory";
import { generatePublicImgURL } from "../../urlConfig";
import { useLoginPhoneUtils } from "../aNewGoToUI/utils/common/loginPhoneUtils";

/**
 * @author
 * @function OrderAdmin
 **/

const ProfessionalAdmin = (props) => {
    const professional = useSelector((state) => state.professional);
    const auth = useSelector((state) => state.auth);
    const [status, setStatus] = useState('');
    const [changeStatus, setChangeStatus] = useState('');
    const dispatch = useDispatch();

    const { handleCallClick } = useLoginPhoneUtils();

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
        },
    };

    const onProfessionalUpdate = (professionalId) => {
        const payload = {
            professionalId,
            'type': changeStatus
        };
        dispatch(updateProfessional(payload));
    };

    useEffect(() => {
        if (auth.isAuthenticated) {
            dispatch(getProfessionals({ status }))
        }
    }, [auth.isAuthenticated, status]);

    return (
        <div className="mx-auto text-center">
            {Object.keys(professional.professionals).length === 0 ? (
                <div>
                    No Professionals
                </div>
            ) : (
                <div className="">
                    <div className=" flex justify-between items-center mx-4">
                        <i className="fas fa-graduation-cap text-primary" /> Filter
                        <select
                            onChange={(e) => setStatus(e.target.value)}
                            className="block my-4 p-1 px-2 rounded-lg bg-black/10"
                        >
                            <option value={''}>All</option>
                            <option key={'applied'} value={'applied'}> Applied </option>
                            <option key={'active'} value={'active'}> Active </option>
                            <option key={'expired'} value={'expired'}> Expired </option>
                        </select>
                    </div>
                    <div className="lg:grid lg:grid-cols-3 md:grid md:grid-cols-2 gap-3">
                        {professional.professionals.map((proItem, index) => (
                            <div key={index} className="bg-black/5  rounded-md p-4 mb-4">
                                <div className="flex justify-between mb-2">
                                    <h2 className="text-lg font-semibold ">
                                        {proItem.address.name}
                                    </h2>
                                    <span
                                        className={`px-2 py-1 pt-1.5 rounded-full text-xs ${proItem.status === "active"
                                            ? "bg-green-500 text-white"
                                            : "bg-red-500 text-white"
                                            }`}
                                    >
                                        {proItem.status}
                                    </span>
                                </div>
                                <div className="mb-2 text-start ml-4">
                                    <p><span className="font-semibold mr-1">Date: </span>{" "} {proItem.appliedDate}</p>
                                    <p>
                                        <span className="font-semibold mr-1">Sector: </span>{" "}
                                        {proItem.sector}
                                    </p>
                                    <p>
                                        <span className="font-semibold mr-1">Service: </span>{" "}
                                        {proItem.service}
                                    </p>
                                    <p>
                                        <span className="font-semibold mr-1">Service: </span>{" "}
                                        {proItem?.choiceOfService?.name}
                                    </p>
                                </div>
                                <div className="mb-2 text-start ml-4">
                                    <p>
                                        <span className="font-semibold  text-start mr-1">Location:</span>{" "}
                                        &nbsp; {proItem.address.pincodeData.poName != 'NA' && (proItem.address.pincodeData.poName)}
                                        {proItem.address.pincodeData.city != 'NA' && (proItem.address.pincodeData.city)}
                                        &nbsp; {proItem.address.pincodeData.district != 'NA' && proItem.address.pincodeData.district}
                                        &nbsp; {proItem.address.pincodeData.stateName != 'NA' && proItem.address.pincodeData.stateName}
                                        &nbsp; {proItem.address.pincodeData.pincode != 'NA' && proItem.address.pincodeData.pincode}
                                        &nbsp; {proItem.address.cityDistrictTown != 'NA' && proItem.address.cityDistrictTown}
                                        &nbsp; {proItem.address.landmark != 'NA' && proItem.address.landmark}
                                        &nbsp; {proItem.address.locality != 'NA' && proItem.address.locality}
                                    </p>
                                </div>
                                <div className="mb-2 text-start ml-4">
                                    <p>
                                        <span className="font-semibold  text-start mr-1">Subscription Details</span>{" "}
                                        {proItem.subscription?.type != 'NA' && proItem.subscription?.type}
                                        &nbsp; {proItem.subscription?.price != 'NA' && proItem.subscription?.price}
                                        &nbsp; {proItem.subscription?.durationInMonths != 'NA' && proItem.subscription?.durationInMonths}
                                    </p>
                                </div>
                                <div className="mb-2 text-start ml-4">
                                    <p>
                                        <span className="font-semibold mr-1">Amount For Enrolment: </span>{" "}
                                        {proItem?.amountForEnrollment}
                                    </p>
                                </div>
                                <>
                                    <button
                                        className="z-30 flex  items-center justify-center items-center bg-black rounded-full text-white py-1 px-4 "
                                        onClick={() => handleCallClick(proItem.mobile)}
                                    >
                                        <MdLocalPhone className="text-white" />
                                        <span className="text-[0.7em] ml-1 ">Call Now</span>
                                    </button>
                                </>
                                {
                                    checkToShowAddProfessionalDetailsButton(proItem, auth) && (
                                        <div className='blue-gradient w-[14em] md:w-[20em] text-white font-extrabold text-xl py-2 text-center m-auto my-4 rounded-full'>
                                            <Link
                                                to={{
                                                    pathname: `/professional/${proItem._id}/details`,
                                                    state: {
                                                        properties: proItem.properties,
                                                        servicePropertyFields: proItem.choiceOfService?.properties ? proItem.choiceOfService.properties : proItem.servicePropertyFields,
                                                        profId: proItem._id
                                                    }
                                                }}
                                            >
                                                Add Professional Details
                                            </Link>
                                        </div>
                                    )
                                }
                                {/* <ServiceCardStatus professional={professional} /> */}
                                <div className="flex justify-between items-center">
                                    <div>
                                        <select
                                            onChange={(e) => setChangeStatus(e.target.value)}
                                            className="block my-2 bg-black/10 p-1 px-2  rounded-lg"
                                        >
                                            <option> Select Status</option>
                                            <option key={'applied'} value={'applied'}> Applied </option>
                                            <option key={'active'} value={'active'}> Active </option>
                                            <option key={'expired'} value={'expired'}> Expired </option>
                                        </select>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => onProfessionalUpdate(proItem._id)}
                                            className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600"
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                                <div className=" mx-2  ">
                                    <Carousel
                                        swipeable={true}
                                        removeArrowOnDeviceType={["mobile"]}
                                        dotListClass="mt-"
                                        itemClass="carousel-item-padding-40-px mb-[6px] ml-2"
                                        responsive={responsive}
                                        autoPlay={1000}
                                        infinite={true}
                                    >
                                        {

                                            proItem?.images.length > 0 && (
                                                proItem?.images.map(image => (
                                                    <img src={generatePublicImgURL('serviceprofessionals/' + image.img)} alt="professionalImage" className="rounded-md md:w-[10em]" />
                                                ))
                                            )
                                        }
                                    </Carousel>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            )}
        </div>
    );
};

export default ProfessionalAdmin;