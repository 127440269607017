import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Carousel from "react-multi-carousel";
import { Modal, Button } from "antd";
import { LuNavigation } from "react-icons/lu";
import "react-multi-carousel/lib/styles.css"; // Import Carousel styles
import getCompleteMobileNumberWithPlus from "../../../utils/MobileNumber";
import { compressImages } from "../../../utils/compressImages";
import { generatePublicImgURL } from "../../../urlConfig";
import { SERVICE_PROFESSIONALS } from "../utils/constants/s3Constants";

const ShowDynamicProperties = ({ properties, servicePropertyFields, imageSectionOrNot }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  console.log("imagesection or not ", imageSectionOrNot);

  const [openModal, setOpenModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleOpenModal = (images) => {
    setOpenModal(true);
    setSelectedImages(images);
  }
  const handleCloseModal = () => {
    setOpenModal(false);
  }

  // Responsive settings for the modal carousel: show one image per frame regardless of screen size
  const modalResponsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 0 }, items: 1 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const getClassNames = (depth) => {
    const baseClass = "w-full";
    const depthClasses = [
      "bg-white", // Depth 0
      "bg-purple-100 shadow-md", // Depth 1
      "bg-[#E3F4F4]", // Depth 2
      "bg-[#F8F6F4]", // Depth 3
    ];
    return `${baseClass} ${depthClasses[depth] || "bg-white"}`;
  };

  const initializeDataMap = (
    servicePropertyFields,
    initialData = {},
    nestedFields = []
  ) => {
    const dataMap = {};
    Object.entries(servicePropertyFields).forEach(([fieldName, fieldValue]) => {
      const nestedFieldsForCurrentField = [...nestedFields, fieldName];
      const nestedFieldPath = nestedFieldsForCurrentField.join(".");

      if (typeof fieldValue === "object" && fieldName !== "multiple") {
        if (fieldValue.type) {
          if (fieldName === "Consent") {
            dataMap[fieldName] = "";
          } else if (
            hasNestedFieldPath(initialData, nestedFieldsForCurrentField)
          ) {
            dataMap[fieldName] = getNestedFieldValue(
              initialData,
              nestedFieldsForCurrentField
            );
          } else {
            dataMap[fieldName] = initializeField(fieldValue);
          }
        } else if (fieldValue.multiple) {
          dataMap[fieldName] = initializeMultipleField(
            fieldValue,
            initialData,
            nestedFieldPath,
            nestedFieldsForCurrentField
          );
        } else {
          dataMap[fieldName] = initializeDataMap(
            fieldValue,
            initialData,
            nestedFieldsForCurrentField
          );
        }
      }
    });
    return dataMap;
  };

  const hasNestedFieldPath = (object, nestedPath) => {
    return nestedPath.every((key) => (object = object[key]) !== undefined);
  };

  const getNestedFieldValue = (initialData, nestedFieldsForCurrentField) => {
    return nestedFieldsForCurrentField.reduce(
      (acc, key) => acc[key],
      initialData
    );
  };

  const initializeField = (fieldValue) => {
    if (fieldValue.multiple) {
      return [];
    }
    switch (fieldValue.type) {
      case "String":
        return "";
      case "Number":
        return null;
      case "Boolean":
        return "";
      case "Date":
        return null;
      case "File":
        return [];
      default:
        return {};
    }
  };

  const initializeMultipleField = (
    fieldValue,
    initialData,
    nestedFieldPath,
    nestedFieldsForCurrentField
  ) => {
    let initialDataArray;
    if(hasNestedFieldPath(initialData, nestedFieldsForCurrentField)) {
      initialDataArray = getNestedFieldValue(initialData, nestedFieldsForCurrentField);
    }
    if (Array.isArray(initialDataArray) && initialDataArray.length > 0) {
      return initialDataArray.map((item, index) =>
        initializeDataMap(fieldValue, initialData, [
          ...nestedFieldsForCurrentField,
          index.toString(),
        ])
      );
    } else {
      return [
        initializeDataMap(fieldValue, initialData, [
          ...nestedFieldsForCurrentField,
          0,
        ]),
      ];
    }
  };

  // console.log("SPF ", servicePropertyFields)
  // console.log("properties ", properties)

  const [propertiesFieldMap, setPropertiesFieldMap] = useState(
    initializeDataMap(servicePropertyFields, properties)
  );

  // Function to handle property changes
  const handlePropertyChange = async (
    e,
    fieldName,
    nestedFields = [],
    isPhoneInput = false,
    isAutoComplete = false
  ) => {
    const updatedPropertiesFieldMap = { ...propertiesFieldMap };
    // Navigate to the nested object based on the nestedFields array
    let currentObject = updatedPropertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }

    if (isPhoneInput) {
      // Handle phone input specifically
      currentObject[fieldName] = getCompleteMobileNumberWithPlus(e);
    } else if (isAutoComplete) {
      // Handle the case for Autocomplete
      currentObject[fieldName] = e;
    } else {
      const { value, files } = e.target;
      let checked;
      if (e.target.type === "checkbox") {
        checked = e.target.checked;
      }
      if (files && files.length > 0) {
        // Handle image input
        const compressedImages = await compressImages(files);
        // Update the value of the field with the compressed images
        currentObject[fieldName] = [
          ...currentObject[fieldName],
          ...compressedImages,
        ];
      } else if (typeof checked !== "undefined") {
        // Handle checkbox input
        currentObject[fieldName] = checked;
      } else {
        // Update the value of the field
        if (Array.isArray(currentObject[fieldName])) {
          // If the field value is an array
          const newValue = value.trim(); // Trim the input value
          const index = currentObject[fieldName].indexOf(newValue);
          if (index !== -1) {
            // If the value is already in the array, remove it
            currentObject[fieldName] = currentObject[fieldName].filter(
              (item, idx) => idx !== index
            );
          } else {
            // Append the value if it doesn't already exist in the array
            currentObject[fieldName] = [...currentObject[fieldName], newValue];
          }
        } else {
          // If the field value is not an array, treat the input value as a single value
          currentObject[fieldName] = value;
        }
      }
    }
    setPropertiesFieldMap(updatedPropertiesFieldMap);
  };

  // Function to render input field for non-nested fields
  const renderInput = (fieldName, nestedFields, required, description) => {
    // Get the current value based on the nestedFields array
    let currentObject = propertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }
    // Update the value of the field
    let value = currentObject[fieldName];
    if (fieldName === "Mobile") {
      return (
        <>
          {
            value && (
              <div>
                <li className="font-bold ">{fieldName}:</li>
                <p className="ml-1 text-gray-800">{value}</p>
              </div>
            )
          }
        </>
      );
    } else {
      return (
        <>
          {
            value && value !== "" && (
              <div className="flex ">
                <li className="font-bold ">{fieldName}:</li>
                <p className="ml-1 text-gray-800">{value}</p>
              </div>
            )
          }
        </>
      );
    }
  };

  // Function to render select dropdown for fields with options
  const renderSelect = (
    options,
    fieldName,
    nestedFields = [],
    multiple,
    required
  ) => {
    // Get the current value based on the nestedFields array
    let currentObject = propertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }
    // Update the value of the field
    let value = currentObject[fieldName];

    // If multiple is true and the value is not an array, convert it to an array
    if (!Array.isArray(value)) {
      value = [value];
    }

    return (
      <div className="flex flex-col">
        {multiple ? (
          <>
            {
              value.length > 0 && <div>
                <li className="font-semibold">{fieldName}</li>
                {value.map((v, i) => (
                  <li key={i} className="text-gray-800 ml-5" style={{ listStyleType: 'circle' }}>
                    {v}
                  </li>
                ))}
              </div>
            }
          </>
        ) : (
          <>
            {
              value[0] !== '' && <div>
                {value.map((v, i) => (
                  <div className="flex gap-3">
                    <li className="font-semibold">{fieldName}</li>
                    <p key={i} className="text-gray-800">
                      {v}
                    </p>
                  </div>
                ))}
              </div>
            }
          </>
        )}
      </div>
    );
  };

  // Function to render input field for number fields
  const renderNumberInput = (
    fieldName,
    nestedFields,
    required,
    description
  ) => {
    // Get the current value based on the nestedFields array
    let currentObject = propertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }
    // Update the value of the field
    let value = currentObject[fieldName];
    return (
      <>{
        value !== undefined && value !== "null" && (
          <div className="flex gap-3 items-start">
            <li className="font-semibold">{fieldName}:</li>
            <p className="ml-1 text-gray-800">{value}</p>
          </div>
        )
      }
      </>
    );
  };

  // Function to render input field for date fields
  const renderDateInput = (fieldName, nestedFields, required, description) => {
    // Get the current value based on the nestedFields array
    let currentObject = propertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }
    // Update the value of the field
    let value = currentObject[fieldName];
    return (
      <>
        {
          value && (
            <div className="flex gap-3 items-start">
              <li className="font-semibold">{fieldName}:</li>
              <p className="ml-1 text-gray-800">{value}</p>
            </div>
          )
        }
      </>
    );
  };

  // Function to render input field for checkbox boolean fields
  const renderCheckBoxInput = (
    fieldName,
    nestedFields,
    required,
    description
  ) => {
    // Get the current value based on the nestedFields array
    let currentObject = propertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }

    // Update the value of the field
    let value = currentObject[fieldName] ? "Yes" : "No";

    return (
      <>
        {
          value && (
            <p className="text-gray-800">{value}</p>
          )
        }
      </>
    );
  };

  // Updated renderImageInput function using Ant Design and Tailwind CSS
  const renderImageInput = (fieldName, nestedFields, required, description) => {
    let currentObject = propertiesFieldMap;
    for (const nestedField of nestedFields) {
      currentObject = currentObject[nestedField];
    }

    const images = currentObject[fieldName] || [];

    return (
      <div className="flex flex-col  space-y-4" style={{ justifyItems: "center" }}>
        {
          images.length > 0 && (
            <li className="font-bold">{fieldName}</li>
          )
        }
        <div className="relative flex flex-col items-center">

          {images.length > 0 && (
            <div className="relative w-40 h-40 z-100">
              <img
                src={generatePublicImgURL(SERVICE_PROFESSIONALS + "/" + images[0])}
                alt={"Property Image"}
                className="w-full h-full rounded-lg object-cover"
              />
              <button
                onClick={() => {
                  handleOpenModal(images);
                }}
                className=" z-100 absolute right-0 bottom-0  p-3  bg-black bg-opacity-50 text-white text-sm  transition-opacity duration-300 rounded-br-lg"
              >
                <LuNavigation />
              </button>
              {renderModal(openModal, handleCloseModal, modalResponsive)}
            </div>
          )}
        </div>


      </div>
    );
  };

  // Function to render input field based on field type
  const renderInputField = (
    fieldName,
    nestedFields = [],
    fieldType,
    required = false,
    description = ""
  ) => {
    switch (fieldType) {
      case "String":
        return renderInput(fieldName, nestedFields, required, description);
      case "Number":
        return renderNumberInput(
          fieldName,
          nestedFields,
          required,
          description
        );
      case "Date":
        return renderDateInput(fieldName, nestedFields, required, description);
      case "Boolean":
        return renderCheckBoxInput(
          fieldName,
          nestedFields,
          required,
          description
        );
      case "File":
        return renderImageInput(fieldName, nestedFields, required);
      default:
        return null;
    }
  };

  // Function to render select field based on field type
  const renderSelectField = (
    fieldName,
    nestedFields = [],
    options,
    fieldType,
    multiple = false,
    required = false,
    description = ""
  ) => {
    switch (fieldType) {
      case "String":
      case "Number":
        return renderSelect(
          options,
          fieldName,
          nestedFields,
          multiple,
          required,
          description
        );
      default:
        return null;
    }
  };

  // Recursive function to render all property fields
  const renderPropertyFields = (
    servicePropertyFields,
    nestedFields = [],
    depth = 0
  ) => {
    return (
      <>
        {Object.entries(servicePropertyFields).map(
          ([fieldName, fieldValue]) => (
            <div
              key={fieldName}
              className="w-full flex  mb-0 gap-4"
            >
              {typeof fieldValue === "object" && fieldValue.type ? (
                <div className={`flex flex-col ml-8 lg:ml-0 lg:items-center w-full`}>
                  {/* Field Title */}
                  {/* {fieldName !== "Consent" && (
                    <h2 className="text-md font-semibold text-orange-800 mb-2">
                      {fieldName}
                    </h2>
                  )} */}
                  {/* Render Field Content */}
                  {Array.isArray(fieldValue.enum)
                    ? renderSelectField(
                      fieldName,
                      nestedFields,
                      fieldValue.enum,
                      fieldValue.type,
                      fieldValue.multiple,
                      fieldValue.required,
                      fieldValue.description
                    )
                    : renderInputField(
                      fieldName,
                      nestedFields,
                      fieldValue.type,
                      fieldValue.required,
                      fieldValue.description
                    )}
                </div>
              ) : fieldValue.multiple &&
                Array.isArray(
                  [...nestedFields, fieldName].reduce(
                    (obj, key) => obj && obj[key],
                    propertiesFieldMap
                  )
                ) ? (
                <div className={` rounded-lg p-2 w-full`}>
                  <h3 className="text-[1.3em] text-center font-semibold flex items-start lg:items-center  mb-6">
                    {fieldName}
                  </h3>
                  {[...nestedFields, fieldName]
                    .reduce((obj, key) => obj && obj[key], propertiesFieldMap)
                    .map((item, index) => (
                      <div
                        key={index}
                        className="mb-0 grid grid-cols-1 md:grid-cols-2 gap-2"
                      >
                        <p className={` font-bold my-4 text-center mb-6`}>{fieldName} {index + 1}</p>
                        {renderPropertyFields(
                          fieldValue,
                          [...nestedFields, fieldName, index.toString()],
                          depth + 1
                        )}
                      </div>
                    ))}
                </div>
              ) : (
                <>
                  {fieldName !== "multiple" && (
                    <>
                      {fieldName === "Images" ? (
                        <div
                          className={` rounded-lg p-2 w-full `}
                        >
                          <h3 className="text-[1.3em] font-semibold flex items-start ml-0 lg:items-center  mb-6">
                            {fieldName}
                          </h3>
                          <div
                            className={`flex lg:flex-row flex-col gap-4 `}
                          >
                            {renderPropertyFields(
                              fieldValue,
                              [...nestedFields, fieldName],
                              depth + 1
                            )}
                          </div>
                        </div>
                      ) : (
                        <div
                          className={` rounded-lg p-2 w-full`}
                        >
                          <h3 className="text-[1.3em] font-semibold flex items-start ml-0 lg:items-center text-center mb-6">
                            {fieldName}
                          </h3>
                          <div
                            className={`grid grid-cols-1 md:grid-cols-2 gap-2`}
                          >
                            {renderPropertyFields(
                              fieldValue,
                              [...nestedFields, fieldName],
                              depth + 1
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )
        )}
      </>
    );
  };

  // Function to append data to FormData (unchanged)
  function appendToFormData(form, obj, parentKey = "") {
    if (
      typeof obj === "string" ||
      typeof obj === "number" ||
      typeof obj === "boolean"
    ) {
      form.append(parentKey, obj);
    } else if (isImage(obj)) {
      form.append(parentKey, obj, obj.name);
    } else {
      Object.entries(obj).forEach(([key, value]) => {
        const newKey = parentKey ? `${parentKey}[${key}]` : key;

        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            const arrayKey = `${newKey}[${index}]`;
            appendToFormData(form, item, arrayKey);
          });
        } else if (typeof value === "object" && value !== null) {
          appendToFormData(form, value, newKey);
        } else if (isImage(value)) {
          const blob = new Blob([value]);
          form.append(newKey, blob, "file");
        } else {
          form.append(newKey, value);
        }
      });
    }
  }

  // Function to check if a value is an image (unchanged)
  function isImage(value) {
    return (
      value instanceof Blob ||
      (value instanceof File && value.type.startsWith("image"))
    );
  }

  function renderModal(openModal, handleCloseModal, modalResponsive) {
    return <Modal
      title="Image Gallery"
      open={openModal}
      onCancel={handleCloseModal}
      footer={[
        <Button key="close" onClick={handleCloseModal}>
          Close
        </Button>,
      ]}
      centered
      width={600}
    >
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={modalResponsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={false}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {selectedImages.map((pic, index) => (
          <div key={index} className="flex justify-center items-center">
            <img
              src={generatePublicImgURL(SERVICE_PROFESSIONALS + "/" + pic)}
              alt={`Image ${index + 1}`}
              className="w-full h-auto object-contain rounded-lg" />
          </div>
        ))}
      </Carousel>
    </Modal>;
  }


  return (
    <div className={`mt-4 p-4`}>
      <div className="flex flex-col items-start lg:items-center justify-center w-full max-w-6xl mx-auto gap-3">
        {renderPropertyFields(servicePropertyFields)}
      </div>
    </div>
  );
};

export default ShowDynamicProperties;